@import "color.css";
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap");
*,
::after,
::before {
  margin: 0;
  box-sizing: border-box;
}

.App {
  font-family: "Comfortaa", cursive;
  background-image: url(./images/bottle-background.svg);
  background-size: cover;
  background-position: center;
  background-color: var(--color-one);
  height: 100vh;
  overflow-x: hidden;
}
