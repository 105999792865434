:root {
  --color-one: #fff7fc;
  --color-one-opaque: #ffffffe1;
  --color-one-more-opaque: #fff7fc67;
  --color-two: #691643;
  --color-two-dark: #4e1032;
  --color-two-opaque: #691644a8;
  --color-three: #a26769;
  --color-three-opaque: #a267699f;
  --color-four: #e0c2c0;
  --color-four-opaque: #e0c2c07c;
  --color-four-opaque-dark: #a8868391;
  --color-five: #57394a;
  --color-five-opaque: #57394a50;
  --color-five-opaque-dark: #57394ac2;
  --color-six: #9a9a9a;
  --color-six-opaque: #9a9a9ace;
  --color-seven: #ffffff;
  --color-eight: #2e1e27;
  --color-nine: #8ea8ac;
  --color-nine-dark: #697c80;
  --color-nine-opaque: #8ea8ac78;
  --color-ten: #fdfd73;
  --color-eleven-opaque: #ffeaea63;
  --color-twelve-opaque: #a267697c;
  --color-thirteen-opaque: #fae7e588;
}
