.routine-info-form {
  display: grid;
  grid-template-columns: 20px auto 20px;
  grid-template-rows: 50px 320px 60px 100px;
  grid-row-gap: 10px;
}

.day-question {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row: 1;
  padding-top: 8%;
  font-size: 0.9rem;
  text-align: center;
}

.weekday-checkboxes {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row: 2;
  padding-top: 8%;
  position: relative;
  left: 9%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 70px 70px 70px 70px;
  min-height: 200px;
}

.friday-right {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
}

.saturday-right {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 3;
}

.sunday-right {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 3;
  grid-row-end: 4;
}

.left {
  grid-column-start: 1;
  grid-column-end: 2;
}

.date-choice {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row: 3;
  font-size: 0.85rem;
  text-align: center;
}

.optional {
  font-size: 0.6rem;
}

.date-input {
  margin-top: 15px;
}

.finish-buttons {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row: 4;
  align-self: center;
  display: flex;
  justify-content: space-around;
}

.quit-modal,
.add-product {
  font-size: 1rem;
  width: 80px;
  height: 35px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  color: var(--color-one);
  font-weight: bold;
}

.quit-modal {
  border: 2px solid var(--color-nine);
  color: var(--color-nine);
  box-shadow: 6px 6px 12px #87969b, -6px -6px 12px #d4dee2;
  background: linear-gradient(145deg, var(--color-one), rgb(204, 204, 204));
  transition: all 200ms ease-in-out;
}

.quit-modal:hover {
  background: linear-gradient(145deg, rgb(204, 204, 204), var(--color-one));
}

.add-product {
  background: linear-gradient(
    145deg,
    var(--color-nine-opaque),
    var(--color-nine)
  );
  box-shadow: 6px 6px 12px #87969b, -6px -6px 12px #d4dee2;
  transition: all 200ms ease-in-out;
}

.add-product:hover {
  background: linear-gradient(
    145deg,
    var(--color-nine),
    var(--color-nine-opaque)
  );
}
.background-blur {
  filter: blur(3px);
}

@media only screen and (min-width: 400px) {
  .weekday-checkboxes {
    left: 12%;
  }
}
