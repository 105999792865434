.product-name-right {
  margin-bottom: 10px;
  background-color: var(--color-eleven-opaque);
  border-radius: 5px;
  text-align: end;
  margin-right: 15px;
  padding: 5px 7px 3px;
  box-shadow: inset 4px 4px 8px #bbadade1, inset -4px -4px 8px #fdebeb;
}

.product-name-left {
  margin-bottom: 10px;
  background-color: var(--color-twelve-opaque);
  border-radius: 5px;
  padding: 5px 7px 3px;
  box-shadow: inset 4px 4px 8px #9d8585, inset -4px -4px 8px #d5b3b5;
}
