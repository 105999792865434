.ProductList {
  display: grid;
  grid-template-rows: 100px auto;
  position: relative;
}

.products-headline {
  display: none;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0px 10px 15px;
  z-index: 1;
  position: fixed;
  top: 0;
  width: 100vw;
  backdrop-filter: blur(8px);
  background-image: linear-gradient(
    0deg,
    rgba(204, 168, 169, 0.26),
    rgba(204, 168, 169, 0.856)
  );
  animation: drop-in 800ms ease-in-out;
}

@keyframes drop-in {
  from {
    position: fixed;
    top: -100px;
  }
  to {
    position: fixed;
    top: 0;
  }
}

.main {
  padding: 190px 10px 30px;
}

.filter-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.product-ul {
  padding: 100px 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  list-style: none;
  padding: 0;
}

.not-modal {
  position: relative;
}

.not-modal::after {
  content: "";
  position: absolute;
  background: rgba(190, 190, 190, 0.336);
  inset: 0;
  z-index: 9;
  backdrop-filter: blur(5px);
}

@media only screen and (min-width: 1200px) {
  .products-headline {
    display: inline;
    color: var(--color-one);
    position: relative;
    left: -110px;
    letter-spacing: 0.2rem;
    font-size: 2.5rem;
  }
}
