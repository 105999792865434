.WrongUrl {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: info-fade-in 900ms ease-in-out;
}

.wrong-url-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  background-color: var(--color-two-opaque);
  color: var(--color-one);
  backdrop-filter: blur(8px);
  border-radius: 10px;
  width: 90vw;
  max-width: 400px;
  height: 300px;
  text-align: center;
}

.redirect {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.redirect > a {
  margin-bottom: 10px;
  color: var(--color-one);
  transition: all 300ms ease-in-out;
}
.redirect > a:hover {
  color: var(--color-eight);
  font-weight: bold;
  letter-spacing: 3px;
  font-size: 20px;
}
