.AddedProductCard {
  width: 270px;
  min-height: 130px;
  height: auto;
  background: linear-gradient(
    145deg,
    var(--color-five-opaque-dark),
    var(--color-five)
  );
  backdrop-filter: blur(5px);
  margin: 40px;
  color: var(--color-one);
  border-radius: 20px;
  position: relative;
  border: 1px solid var(--color-five-opaque-dark);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 8px 12px;
  animation: appear 1.2s ease-in-out backwards;
}

@keyframes appear {
  0% {
    transform: scale(0);
  }
  80% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.added-product-name {
  margin-top: 5px;
  width: 18ch;
}

.added-bottle-image {
  width: 250px;
  position: absolute;
  right: -93px;
  bottom: -3px;
  pointer-events: none;
  animation: swipe-in-right 700ms 1.1s ease-in-out backwards;
}

@keyframes swipe-in-right {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.smaller-added-image {
  width: 200px;
  position: absolute;
  right: -63px;
  bottom: -3px;
  pointer-events: none;
}

.added-powder-image {
  width: 180px;
  position: absolute;
  right: -60px;
  bottom: 50px;
  transform: rotate(20deg);
  pointer-events: none;
}

.time-details {
  margin: 10px 0;
}

.restock {
  margin-bottom: 5px;
}

.shopping-link {
  color: var(--color-one);
}

.edit-button {
  position: absolute;
  bottom: 15%;
  right: 80px;
  border: 2px solid var(--color-one);
  background-color: transparent;
  border-radius: 5px;
  font-size: 1rem;
  padding: 3px 8px;
  color: var(--color-one);
  cursor: pointer;
  transition: all 300ms ease-in-out;
}

.edit-button:hover {
  transform: translateY(-3px);
}

.AddedProductCard p {
  font-size: 0.8rem;
}

.shopping-cart-icon {
  position: relative;
  top: 4px;
  font-size: 1rem;
}

.shopping-cart-icon:hover {
  animation: shake 1s;
}

@keyframes shake {
  0% {
    transform: rotate(10deg);
  }
  25% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(10deg);
  }
  75% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(10deg);
  }
}
