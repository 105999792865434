@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --color-one: #fff7fc;
  --color-one-opaque: #ffffffe1;
  --color-one-more-opaque: #fff7fc67;
  --color-two: #691643;
  --color-two-dark: #4e1032;
  --color-two-opaque: #691644a8;
  --color-three: #a26769;
  --color-three-opaque: #a267699f;
  --color-four: #e0c2c0;
  --color-four-opaque: #e0c2c07c;
  --color-four-opaque-dark: #a8868391;
  --color-five: #57394a;
  --color-five-opaque: #57394a50;
  --color-five-opaque-dark: #57394ac2;
  --color-six: #9a9a9a;
  --color-six-opaque: #9a9a9ace;
  --color-seven: #ffffff;
  --color-eight: #2e1e27;
  --color-nine: #8ea8ac;
  --color-nine-dark: #697c80;
  --color-nine-opaque: #8ea8ac78;
  --color-ten: #fdfd73;
  --color-eleven-opaque: #ffeaea63;
  --color-twelve-opaque: #a267697c;
  --color-thirteen-opaque: #fae7e588;
}

*,
::after,
::before {
  margin: 0;
  box-sizing: border-box;
}

.App {
  font-family: "Comfortaa", cursive;
  background-image: url(/static/media/bottle-background.6b5d3f09.svg);
  background-size: cover;
  background-position: center;
  background-color: var(--color-one);
  height: 100vh;
  overflow-x: hidden;
}

.Home {
  position: relative;
}

.headline {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0;
  z-index: 1;
  font-size: 3rem;
  position: relative;
  top: 0;
  width: 100vw;
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
  background-image: linear-gradient(
    0deg,
    rgba(204, 168, 169, 0.26),
    rgba(204, 168, 169, 0.856)
  );
  -webkit-animation: headline-drop-in 800ms ease-in-out;
          animation: headline-drop-in 800ms ease-in-out;
  color: var(--color-one);
}

@-webkit-keyframes headline-drop-in {
  from {
    top: -80px;
  }
  to {
    top: 0;
  }
}

@keyframes headline-drop-in {
  from {
    top: -80px;
  }
  to {
    top: 0;
  }
}

.product-link {
  position: absolute;
  top: 222px;
  left: 5px;
  font-weight: 700;
  font-size: 1rem;
  letter-spacing: 0.2rem;
  z-index: 1;
  text-decoration: none;
  color: var(--color-two);
}

.bottle-one {
  width: 120px;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  opacity: 0.9;
  position: absolute;
  top: 55px;
  left: 50px;
}

.product-link:hover + .bottle-one,
.bottle-one:hover {
  opacity: 1;
  cursor: pointer;
}

.bottle-wrapper-one:hover > .product-link {
  color: var(--color-five);
}

.bottle-wrapper-one {
  -webkit-animation: slide-in-left 900ms cubic-bezier(0.8, 0.02, 0.54, 1.53) backwards;
          animation: slide-in-left 900ms cubic-bezier(0.8, 0.02, 0.54, 1.53) backwards;
  position: relative;
  top: -50px;
}

@-webkit-keyframes slide-in-left {
  from {
    left: -300px;
  }
  to {
    left: 5px;
  }
}

@keyframes slide-in-left {
  from {
    left: -300px;
  }
  to {
    left: 5px;
  }
}

.routine-link {
  position: absolute;
  top: 420px;
  right: 5px;
  font-weight: 700;
  font-size: 1rem;
  letter-spacing: 0.2rem;
  z-index: 1;
  text-decoration: none;
  color: var(--color-two);
}

.bottle-two {
  width: 120px;
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
  opacity: 0.9;
  position: absolute;
  top: 250px;
  right: 70px;
}

.routine-link:hover + .bottle-two,
.bottle-two:hover {
  opacity: 1;
  cursor: pointer;
}

.bottle-wrapper-two:hover > .routine-link {
  color: var(--color-three);
}

.bottle-wrapper-two {
  -webkit-animation: slide-in-right 900ms 500ms cubic-bezier(0.44, -0.07, 0.67, 1.5)
    backwards;
          animation: slide-in-right 900ms 500ms cubic-bezier(0.44, -0.07, 0.67, 1.5)
    backwards;
  position: relative;
  top: -50px;
}

@-webkit-keyframes slide-in-right {
  from {
    left: 310px;
  }
  to {
    left: 5px;
  }
}

@keyframes slide-in-right {
  from {
    left: 310px;
  }
  to {
    left: 5px;
  }
}

@media only screen and (min-width: 1200px) {
  .home-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .bottle-wrapper-one {
    position: relative;
    top: -80px;
    right: 60px;
    height: 250px;
    -webkit-animation: slide-in-left 1.3s 200ms cubic-bezier(0.44, -0.07, 0.67, 1.5)
      backwards;
            animation: slide-in-left 1.3s 200ms cubic-bezier(0.44, -0.07, 0.67, 1.5)
      backwards;
  }

  @-webkit-keyframes slide-in-left {
    from {
      right: 1500px;
    }
    to {
      right: 60px;
    }
  }

  @keyframes slide-in-left {
    from {
      right: 1500px;
    }
    to {
      right: 60px;
    }
  }

  .bottle-wrapper-two {
    position: relative;
    top: -60px;
    right: -60px;
    height: 250px;
    -webkit-animation: slide-in-right 1.3s 200ms cubic-bezier(0.44, -0.07, 0.67, 1.3)
      backwards;
            animation: slide-in-right 1.3s 200ms cubic-bezier(0.44, -0.07, 0.67, 1.3)
      backwards;
  }

  @-webkit-keyframes slide-in-right {
    from {
      right: -1500px;
    }
    to {
      right: -60px;
    }
  }

  @keyframes slide-in-right {
    from {
      right: -1500px;
    }
    to {
      right: -60px;
    }
  }

  .bottle-one {
    width: 200px;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    opacity: 0.9;
  }

  .bottle-two {
    position: absolute;
    top: 40px;
    width: 200px;
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
    opacity: 0.9;
  }

  .product-link {
    position: absolute;
    letter-spacing: 1px;
    font-size: 1.9rem;
    width: 500px;
    top: 333px;
    left: -137px;
    z-index: 10;
    text-decoration: none;
    color: var(--color-one);
  }

  .routine-link {
    position: absolute;
    letter-spacing: 1px;
    font-size: 1.9rem;
    width: 500px;
    top: 320px;
    left: -298px;
    z-index: 10;
    text-decoration: none;
    color: var(--color-one);
  }
}

.ProductList {
  display: grid;
  grid-template-rows: 100px auto;
  position: relative;
}

.products-headline {
  display: none;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0px 10px 15px;
  z-index: 1;
  position: fixed;
  top: 0;
  width: 100vw;
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
  background-image: linear-gradient(
    0deg,
    rgba(204, 168, 169, 0.26),
    rgba(204, 168, 169, 0.856)
  );
  -webkit-animation: drop-in 800ms ease-in-out;
          animation: drop-in 800ms ease-in-out;
}

@-webkit-keyframes drop-in {
  from {
    position: fixed;
    top: -100px;
  }
  to {
    position: fixed;
    top: 0;
  }
}

@keyframes drop-in {
  from {
    position: fixed;
    top: -100px;
  }
  to {
    position: fixed;
    top: 0;
  }
}

.main {
  padding: 190px 10px 30px;
}

.filter-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.product-ul {
  padding: 100px 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: 30px;
  gap: 30px;
  list-style: none;
  padding: 0;
}

.not-modal {
  position: relative;
}

.not-modal::after {
  content: "";
  position: absolute;
  background: rgba(190, 190, 190, 0.336);
  inset: 0;
  z-index: 9;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}

@media only screen and (min-width: 1200px) {
  .products-headline {
    display: inline;
    color: var(--color-one);
    position: relative;
    left: -110px;
    letter-spacing: 0.2rem;
    font-size: 2.5rem;
  }
}

.NameFilter {
  margin-bottom: 10px;
  border: 2px solid var(--color-three);
  border-radius: 30px;
  display: flex;
  height: 40px;
  width: 40px;
  padding: 3px;
  position: relative;
  transition: all 300ms ease-in-out;
  overflow: hidden;
  box-shadow: 0 0 10px var(--color-three);
}

.NameFilter:hover {
  border: 2px solid var(--color-two);
}

.name-search-bar {
  flex-grow: 1;
  font-size: 1rem;
  background-color: transparent;
  color: var(--color-two);
  border: none;
  padding: 0 10px 5px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  line-height: 35px;
  opacity: 0;
  cursor: pointer;
}

.name-search-bar:focus {
  outline: 0;
}

.NameFilter:focus-within {
  width: 270px;
  border: 2px solid var(--color-two);
}

.NameFilter:focus-within .name-search-bar {
  opacity: 1;
  cursor: text;
  width: 200px;
}

.name-search-button {
  font-size: 1.2em;
  border: none;
  background: transparent;
  border-radius: 50%;
  cursor: pointer;
  color: var(--color-two);
  padding: 6px;
  margin-left: auto;
  transition: 600ms ease-in-out;
}

.NameFilter:focus-within .name-search-button {
  background-color: var(--color-three);
  color: var(--color-one);
}

.calendar {
  font-size: 50px;
  border: 1px solid var(--color-two);
  border-radius: 5px;
  padding: 5px 5px 5px 7px;
  background: #c7bdc3;
  box-shadow: inset 4px 4px 8px #a9a1a6, inset -4px -4px 8px #e5d9e0;
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
}

.weekly-routine-link {
  text-decoration: none;
  color: var(--color-two);
  margin-right: 15px;
}

.calendar:hover,
.calendar:hover > .weekly-routine-link {
  background-color: var(--color-two);
  color: var(--color-one);
  box-shadow: none;
}

.ProductCard {
  width: 270px;
  height: 200px;
  background: linear-gradient(
    145deg,
    var(--color-two-opaque) 40%,
    var(--color-two)
  );
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
  border-radius: 35px;
  margin: 0 15px 50px 15px;
  position: relative;
  border: 1px solid var(--color-two-opaque);
  -webkit-animation: fade-in 1s ease-in-out backwards;
          animation: fade-in 1s ease-in-out backwards;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
  transition: 300ms ease-in-out;
}

.ProductCard:hover {
  -webkit-transform: translateY(-20px);
          transform: translateY(-20px);
}

@-webkit-keyframes fade-in {
  from {
    height: 160px;
    opacity: 0;
  }
  to {
    height: 200px;
    opacity: 1;
  }
}

@keyframes fade-in {
  from {
    height: 160px;
    opacity: 0;
  }
  to {
    height: 200px;
    opacity: 1;
  }
}

.ProductCard-headline {
  position: relative;
  right: 7%;
  width: 20ch;
  color: var(--color-seven);
  font-size: 0.8rem;
}

.bottle-image {
  position: absolute;
  left: -165px;
  bottom: -15px;
  width: 380px;
  -webkit-transform: rotate(-15deg);
          transform: rotate(-15deg);
  z-index: 0;
  pointer-events: none;
  -webkit-animation: rotate 1s ease-in-out backwards;
          animation: rotate 1s ease-in-out backwards;
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(-30deg);
            transform: rotate(-30deg);
  }
  to {
    -webkit-transform: rotate(-15deg);
            transform: rotate(-15deg);
  }
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(-30deg);
            transform: rotate(-30deg);
  }
  to {
    -webkit-transform: rotate(-15deg);
            transform: rotate(-15deg);
  }
}

.smaller-image {
  position: absolute;
  left: -110px;
  bottom: -5px;
  width: 290px;
}

.powder {
  position: absolute;
  left: -85px;
  bottom: -5px;
  width: 250px;
}

.details-link {
  position: relative;
  right: 25%;
  text-decoration: none;
  color: var(--color-one);
  border: 2px solid var(--color-one);
  padding: 5px 8px;
  border-radius: 5px;
  background-color: var(--color-four-opaque);
  -webkit-backdrop-filter: blur(1px);
          backdrop-filter: blur(1px);
  background: linear-gradient(145deg, #946c7dd0, #af8095d0);
  box-shadow: 4px 4px 18px #33252b;
  transition: 100ms ease-in-out;
}

.details-link:hover {
  -webkit-transform: translateY(3px);
          transform: translateY(3px);
  box-shadow: none;
}

.add-to-routine-button {
  position: relative;
  right: 5%;
  color: var(--color-two-opaque);
  background: linear-gradient(145deg, #ffffff, #c5bfc2);
  box-shadow: 3px 3px 9px var(--color-two-opaque),
    -3px -3px 19px var(--color-one-opaque);
  border: none;
  border-radius: 10px;
  font-size: 1.7rem;
  line-height: 2rem;
  width: 45px;
  height: 45px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 300ms ease-in-out;
}

.add-to-routine-button:hover {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.long-name {
  position: relative;
  right: 5%;
  bottom: 2%;
  color: var(--color-two-opaque);
  background: linear-gradient(145deg, #ffffff, #c5bfc2);
  box-shadow: 3px 3px 9px var(--color-two-opaque),
    -3px -3px 9px var(--color-one-opaque);
  border: none;
  border-radius: 10px;
  font-size: 1.7rem;
  line-height: 2rem;
  width: 45px;
  height: 45px;
  cursor: pointer;
}

.label {
  display: none;
}

.category-search-bar {
  width: 270px;
  font-size: 1.3rem;
  border: 2px solid var(--color-three-opaque);
  border-radius: 5px;
  color: var(--color-two);
  padding-bottom: 2px;
  background: var(--color-one-opaque);
  -webkit-backdrop-filter: blur(1px);
          backdrop-filter: blur(1px);
  cursor: pointer;
  box-shadow: 2px 2px 12px rgb(170, 170, 170);
}

.category-search-bar:focus {
  border: 2px solid var(--color-two);
}

.FormModal {
  position: absolute;
  width: 100vw;
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  overflow-y: scroll;
  overflow-x: hidden;
  position: fixed;
  top: 55px;
  width: 90vw;
  max-width: 450px;
  height: 85vh;
  max-height: 600px;
  background-color: var(--color-one-opaque);
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
  flex-grow: 1;
  z-index: 10;
  border-radius: 30px;
  box-shadow: 0px 20px 50px 5px #00000081;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color-one);
  -webkit-animation: move-up 1s ease-in-out 1;
          animation: move-up 1s ease-in-out 1;
}

@-webkit-keyframes move-up {
  from {
    -webkit-transform: translateY(650px);
            transform: translateY(650px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

@keyframes move-up {
  from {
    -webkit-transform: translateY(650px);
            transform: translateY(650px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

.routine-info-form {
  display: grid;
  grid-template-columns: 20px auto 20px;
  grid-template-rows: 50px 320px 60px 100px;
  grid-row-gap: 10px;
}

.day-question {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row: 1;
  padding-top: 8%;
  font-size: 0.9rem;
  text-align: center;
}

.weekday-checkboxes {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row: 2;
  padding-top: 8%;
  position: relative;
  left: 9%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 70px 70px 70px 70px;
  min-height: 200px;
}

.friday-right {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
}

.saturday-right {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 3;
}

.sunday-right {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 3;
  grid-row-end: 4;
}

.left {
  grid-column-start: 1;
  grid-column-end: 2;
}

.date-choice {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row: 3;
  font-size: 0.85rem;
  text-align: center;
}

.optional {
  font-size: 0.6rem;
}

.date-input {
  margin-top: 15px;
}

.finish-buttons {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row: 4;
  align-self: center;
  display: flex;
  justify-content: space-around;
}

.quit-modal,
.add-product {
  font-size: 1rem;
  width: 80px;
  height: 35px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  color: var(--color-one);
  font-weight: bold;
}

.quit-modal {
  border: 2px solid var(--color-nine);
  color: var(--color-nine);
  box-shadow: 6px 6px 12px #87969b, -6px -6px 12px #d4dee2;
  background: linear-gradient(145deg, var(--color-one), rgb(204, 204, 204));
  transition: all 200ms ease-in-out;
}

.quit-modal:hover {
  background: linear-gradient(145deg, rgb(204, 204, 204), var(--color-one));
}

.add-product {
  background: linear-gradient(
    145deg,
    var(--color-nine-opaque),
    var(--color-nine)
  );
  box-shadow: 6px 6px 12px #87969b, -6px -6px 12px #d4dee2;
  transition: all 200ms ease-in-out;
}

.add-product:hover {
  background: linear-gradient(
    145deg,
    var(--color-nine),
    var(--color-nine-opaque)
  );
}
.background-blur {
  -webkit-filter: blur(3px);
          filter: blur(3px);
}

@media only screen and (min-width: 400px) {
  .weekday-checkboxes {
    left: 12%;
  }
}

.Checkbox {
  padding: 10px 0;
}

.time-of-day {
  padding-top: 5px;
}

.weekday-checkbox,
.morning-checkbox,
.evening-checkbox {
  cursor: pointer;
}

.weekday-label,
.morning-label,
.evening-label {
  padding-left: 5px;
  cursor: pointer;
}

.morning,
.evening {
  position: relative;
  font-size: 0.9rem;
}

.morning {
  padding-right: 15px;
}

.morning-label,
.evening-label {
  position: relative;
  top: 4px;
  font-size: 1.1rem;
}

.alert-modal {
  font-size: 0.9rem;
  color: var(--color-one);
  background: linear-gradient(
    145deg,
    var(--color-three-opaque),
    var(--color-three)
  );
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  z-index: 100;
  text-align: center;
  border-radius: 15px;
  border: 1px solid var(--color-three-opaque);
  padding: 20px 10px 5px;
  position: fixed;
  top: 18vh;
  left: 8vw;
  right: 8vw;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  grid-gap: 30px;
  gap: 30px;
  -webkit-animation: alert-pop-up 800ms ease-in-out;
          animation: alert-pop-up 800ms ease-in-out;
}

@-webkit-keyframes alert-pop-up {
  from {
    position: fixed;
    top: -150px;
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  to {
    position: fixed;
    top: 18vh;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes alert-pop-up {
  from {
    position: fixed;
    top: -150px;
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  to {
    position: fixed;
    top: 18vh;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.alert-modal-finishing-button-wrapper {
  padding-bottom: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.alert-message {
  line-height: 1.3rem;
}

.alert-modal-finishing-button-wrapper > button {
  border-radius: 8px;
  font-weight: bold;
  border: none;
  width: 100px;
  height: 40px;
  color: var(--color-one);
  cursor: pointer;
}

.cancel-alert {
  background: linear-gradient(
    145deg,
    var(--color-nine) 30%,
    var(--color-nine-dark)
  );
  box-shadow: 6px 6px 12px #212729ec, -3px -3px 20px 0px #dce2e4fd;
  transition: all 300ms ease-in-out;
}

.swap-product {
  background: linear-gradient(
    145deg,
    var(--color-two) 30%,
    var(--color-two-dark)
  );
  box-shadow: 6px 6px 12px #3d122a, -6px -6px 22px #f8d2e7;
  transition: all 300ms ease-in-out;
}

.swap-product:hover,
.cancel-alert:hover,
.add-product:hover,
.quit-modal:hover {
  -webkit-transform: translateY(3px);
          transform: translateY(3px);
  box-shadow: none;
}

@media only screen and (min-width: 500px) {
  .alert-modal {
    right: 15%;
    left: 15%;
  }
}

.WarnModal {
  height: 180px;
  width: 300px;
  background-color: var(--color-two);
  position: absolute;
  top: 20%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);

  z-index: 100;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;

  border-radius: 15px;
  font-size: 0.9rem;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
  color: var(--color-one);
  padding: 0 10px;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.486);
}

.warn-button-okay {
  cursor: pointer;
  color: var(--color-one);
  padding: 10px 15px;
  letter-spacing: 1px;
  border: none;
  border-radius: 7px;
  background: linear-gradient(145deg, #701847, #5f143b);
  box-shadow: 3px 3px 7px #3a0c25, -3px -3px 7px #941d5c;
}

.warn-button-okay:hover {
  background: linear-gradient(145deg, #5f143b, #701847);
}

.warn-button-okay:active {
  background: #691642;
  box-shadow: inset 3px 3px 7px #3f0d28, inset -3px -3px 7px #881c55;
}

.WeeklyRoutine {
  display: grid;
  grid-template-rows: 100px auto;
  position: relative;
  overflow-x: hidden;
}

.weekly-headline {
  background-image: linear-gradient(
    0deg,
    rgba(204, 168, 169, 0.26),
    rgba(204, 168, 169, 0.856)
  );
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
  text-align: center;
  padding: 15px 0;
  color: var(--color-one);
  position: fixed;
  width: 100vw;
  z-index: 5;
  -webkit-animation: drop-in 500ms ease-in-out;
          animation: drop-in 500ms ease-in-out;
}

.weekly-main {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100vw;
}

.add-button-weekly {
  text-decoration: none;
  position: fixed;
  bottom: 20px;
  padding: 5px 16px 0px;
  border-radius: 50%;
  font-size: 3rem;
  font-weight: bold;
  border: 5px solid var(--color-two);
  color: var(--color-two);
  background-color: var(--color-thirteen-opaque);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  cursor: pointer;
  -webkit-animation: button-up 800ms cubic-bezier(0.8, 0.02, 0.54, 1.83) 800ms backwards;
          animation: button-up 800ms cubic-bezier(0.8, 0.02, 0.54, 1.83) 800ms backwards;
  transition: -webkit-transform 300ms ease-in-out;
  transition: transform 300ms ease-in-out;
  transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
  z-index: 5;
}

.add-button-weekly:hover {
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
}

@-webkit-keyframes button-up {
  from {
    opacity: 0;
    bottom: 100px;
  }
  to {
    opacity: 1;
    bottom: 20px;
  }
}

@keyframes button-up {
  from {
    opacity: 0;
    bottom: 100px;
  }
  to {
    opacity: 1;
    bottom: 20px;
  }
}

.empty-routine-background {
  background-color: var(--color-one);
  height: 100%;
  overflow-y: hidden;
}

.empty-slogan {
  text-align: center;
  -webkit-transform: translateX(12%);
          transform: translateX(12%);
}

.dropper {
  width: 290px;
  position: relative;
  left: -53px;
  bottom: 35px;
}

.bottle-row {
  width: 2920px;
  position: fixed;
  bottom: 0px;
  left: 0;
}

.empty-routine-button {
  position: relative;
  -webkit-animation: none;
          animation: none;
  bottom: -235px;
  right: 141px;
  border: none;
  background-color: transparent;
  color: var(--color-three);
  font-size: 70px;
  font-weight: lighter;
  -webkit-backdrop-filter: none;
          backdrop-filter: none;
  z-index: 5;
}

.empty-routine-button:hover {
  -webkit-transform: none;
          transform: none;
  color: var(--color-nine);
}

@media only screen and (min-height: 750px) {
  .dropper {
    width: 290px;
    position: relative;
    left: -53px;
    bottom: -45px;
  }

  .empty-routine-button {
    bottom: -310px;
  }
}

@media only screen and (max-width: 360px) {
  .empty-slogan {
    -webkit-transform: none;
            transform: none;
  }
  .dropper {
    left: -90px;
  }
  .empty-routine-button {
    bottom: 187px;
    right: -4px;
  }
}

.WeekDayCard {
  width: 95vw;
  max-width: 730px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  color: var(--color-eight);
}

.WeekDayCard:last-child {
  margin-bottom: 70px;
}

.weekday-headline {
  margin: 0 0 10px 10px;
  font-size: 1.4rem;
  text-shadow: 2px 2px 2px rgba(150, 150, 150, 1);
}

.weekday-box {
  height: auto;
  width: 95vw;
  max-width: 530px;
  background-color: var(--color-four-opaque-dark);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  border-radius: 30px;
  border: 1px solid var(--color-four-opaque);
  margin-bottom: 40px;
  position: relative;
  box-shadow: 0 15px 30px 5px rgba(0, 0, 0, 0.3);
  display: grid;
  grid-template-columns: [left] minmax(166.25px, 360px) [right] minmax(
      166.25px,
      360px
    );
  grid-template-rows: auto, auto auto;
  -webkit-animation: entrance 800ms ease-in-out;
          animation: entrance 800ms ease-in-out;
}

@-webkit-keyframes entrance {
  from {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
    z-index: -1;
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
    z-index: 2;
  }
}

@keyframes entrance {
  from {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
    z-index: -1;
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
    z-index: 2;
  }
}

.weekday-box::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 50%;
  background-color: rgba(255, 255, 255, 0.287);
  border-radius: 30px 0 0 30px;
}

.weekday-box-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-column-start: 1;
  grid-column-end: 3;
  padding: 5px 25px 0;
  color: var(--color-ten);
  font-size: 1.5rem;
  margin: 10px 0 15px;
}

.sun {
  font-size: 2.1rem;
}

.product-name-list {
  list-style: none;
  padding-left: 15px;
  font-size: 0.75rem;
}

.morning {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;
  max-width: 285px;
}

.evening {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 3;
  max-width: 285px;
  justify-self: end;
}

.see-details-button-wrapper {
  grid-column-start: 1;
  grid-column-end: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  z-index: 1;
}

.see-details-button {
  padding: 15px 20px;
  border-radius: 15px;
  border: none;
  background: linear-gradient(145deg, #c28585, #8b5f5f);
  color: var(--color-one);
  font-weight: bold;
  font-size: 0.9rem;
  box-shadow: 0px 5px 18px #835959e7;
  cursor: pointer;
  transition: all 300ms ease-in-out;
}

.see-details-button:hover {
  background: linear-gradient(145deg, #8b5f5f, #c28585);
  box-shadow: none;
}

.product-name-right {
  margin-bottom: 10px;
  background-color: var(--color-eleven-opaque);
  border-radius: 5px;
  text-align: end;
  margin-right: 15px;
  padding: 5px 7px 3px;
  box-shadow: inset 4px 4px 8px #bbadade1, inset -4px -4px 8px #fdebeb;
}

.product-name-left {
  margin-bottom: 10px;
  background-color: var(--color-twelve-opaque);
  border-radius: 5px;
  padding: 5px 7px 3px;
  box-shadow: inset 4px 4px 8px #9d8585, inset -4px -4px 8px #d5b3b5;
}

.DailyRoutine {
  display: grid;
  grid-template-rows: 100px auto;
  position: relative;
}

.daily-headline {
  background-image: linear-gradient(
    0deg,
    rgba(204, 168, 169, 0.26),
    rgba(204, 168, 169, 0.856)
  );
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
  color: var(--color-one);
  text-align: center;
  padding: 15px 0;
  position: fixed;
  width: 100vw;
  z-index: 1;
  -webkit-animation: drop-in 500ms ease-in-out;
          animation: drop-in 500ms ease-in-out;
}

.history-back {
  color: var(--color-two);
  border: 2px solid var(--color-two);
  border-radius: 6px;
  margin-right: 25px;
  font-size: 1.2rem;
  padding: 8px 8px 3px 8px;
  background-color: var(--color-one-opaque);
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
  position: fixed;
  right: -15px;
  top: 10px;
  z-index: 1;
  box-shadow: 0 5px 20px 1px var(--color-five-opaque-dark);
  transition: all 300ms ease-in-out;
}

.history-back:hover {
  background-color: var(--color-two);
  color: var(--color-one-opaque);
  cursor: pointer;
  box-shadow: none;
}

.daily-main {
  padding-top: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.daily-not-modal {
  min-height: 100vh;
  position: relative;
}

.daily-not-modal::after {
  content: "";
  position: absolute;
  background: rgba(190, 190, 190, 0.336);
  inset: 0;
  z-index: 9;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}

.morning-products-display,
.evening-products-display {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
}

.morning-products-list,
.evening-products-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
}

.added-product-list-item {
  list-style: none;
}

.AddedProductCard {
  width: 270px;
  min-height: 130px;
  height: auto;
  background: linear-gradient(
    145deg,
    var(--color-five-opaque-dark),
    var(--color-five)
  );
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  margin: 40px;
  color: var(--color-one);
  border-radius: 20px;
  position: relative;
  border: 1px solid var(--color-five-opaque-dark);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 8px 12px;
  -webkit-animation: appear 1.2s ease-in-out backwards;
          animation: appear 1.2s ease-in-out backwards;
}

@-webkit-keyframes appear {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  80% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes appear {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  80% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.added-product-name {
  margin-top: 5px;
  width: 18ch;
}

.added-bottle-image {
  width: 250px;
  position: absolute;
  right: -93px;
  bottom: -3px;
  pointer-events: none;
  -webkit-animation: swipe-in-right 700ms 1.1s ease-in-out backwards;
          animation: swipe-in-right 700ms 1.1s ease-in-out backwards;
}

@-webkit-keyframes swipe-in-right {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes swipe-in-right {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.smaller-added-image {
  width: 200px;
  position: absolute;
  right: -63px;
  bottom: -3px;
  pointer-events: none;
}

.added-powder-image {
  width: 180px;
  position: absolute;
  right: -60px;
  bottom: 50px;
  -webkit-transform: rotate(20deg);
          transform: rotate(20deg);
  pointer-events: none;
}

.time-details {
  margin: 10px 0;
}

.restock {
  margin-bottom: 5px;
}

.shopping-link {
  color: var(--color-one);
}

.edit-button {
  position: absolute;
  bottom: 15%;
  right: 80px;
  border: 2px solid var(--color-one);
  background-color: transparent;
  border-radius: 5px;
  font-size: 1rem;
  padding: 3px 8px;
  color: var(--color-one);
  cursor: pointer;
  transition: all 300ms ease-in-out;
}

.edit-button:hover {
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
}

.AddedProductCard p {
  font-size: 0.8rem;
}

.shopping-cart-icon {
  position: relative;
  top: 4px;
  font-size: 1rem;
}

.shopping-cart-icon:hover {
  -webkit-animation: shake 1s;
          animation: shake 1s;
}

@-webkit-keyframes shake {
  0% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
  25% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  50% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
  75% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  100% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
}

@keyframes shake {
  0% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
  25% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  50% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
  75% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  100% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
}

@media only screen and (max-width: 375px) {
  .SingleProduct {
    display: grid;
    min-height: 100%;
    height: auto;
    grid-template-rows: 350px 70px auto;
    background-color: var(--color-one-opaque);
  }

  .single-product-image {
    display: block;
    margin: 0 auto;
    background-color: var(--color-three-opaque);
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
    width: 100%;
    position: relative;
    top: -60px;
    border-radius: 0 0 35px 35px;
    padding-bottom: 30px;
    -webkit-animation: image-drop-in 600ms ease-in-out backwards;
            animation: image-drop-in 600ms ease-in-out backwards;
    box-shadow: 0 10px 30px #412829;
  }

  @-webkit-keyframes image-drop-in {
    from {
      top: -500px;
    }
    to {
      top: -60px;
    }
  }

  @keyframes image-drop-in {
    from {
      top: -500px;
    }
    to {
      top: -60px;
    }
  }

  .toner-image {
    width: 100%;
    padding: 60px 30px 10px 30px;
  }

  .single-product-name {
    padding: 20px 0 30px;
    text-align: center;
    -webkit-animation: info-fade-in 600ms ease-in-out;
            animation: info-fade-in 600ms ease-in-out;
  }

  .single-product-info {
    margin: 0 15px;
    -webkit-animation: info-fade-in 600ms ease-in-out;
            animation: info-fade-in 600ms ease-in-out;
  }

  @-webkit-keyframes info-fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes info-fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .single-product-refrigeration {
    margin: 0 20px 20px 0;
  }

  .single-product-refrigeration-icon {
    margin-right: 3px;
    position: relative;
    top: 2px;
  }

  .single-product-refrigeration-text {
    padding: 3px;
    background-color: var(--color-one-opaque);
    border-radius: 3px;
    margin-left: 3px;
  }

  .single-product-time {
    margin-bottom: 20px;
  }

  .single-product-time-icon {
    position: relative;
    top: 3px;
    margin-right: 3px;
  }

  .single-product-time-text {
    padding: 3px;
    background-color: var(--color-one-opaque);
    border-radius: 3px;
    margin-left: 3px;
  }

  .single-product-goal-list {
    margin-top: 25px;
    line-height: 1.5;
    font-size: 0.9rem;
    max-width: 85vw;
  }

  .single-product-goal-list > li {
    margin-top: 5px;
    background-color: var(--color-one-opaque);
    border-radius: 5px;
  }

  .single-product-ingredients {
    margin: 30px 0;
  }

  .single-product-ingredients > h4 {
    padding: 3px;
    background-color: var(--color-one-opaque);
    border-radius: 3px;
    width: 100%;
    display: inline;
  }

  .single-product-ingredients-list {
    margin-top: 10px;
    font-size: 0.8rem;
    background-color: var(--color-one-opaque);
    -webkit-backdrop-filter: blur(3px);
            backdrop-filter: blur(3px);
    padding: 5px;
    border-radius: 5px;
    line-height: 1.4;
  }

  .single-product-back-button {
    position: fixed;
    right: 15px;
    bottom: 15px;
    color: var(--color-two);
    border: 2px solid var(--color-two);
    border-radius: 6px;
    font-size: 1.2rem;
    padding: 8px 8px 3px 8px;
    background-color: var(--color-one-opaque);
    -webkit-backdrop-filter: blur(3px);
            backdrop-filter: blur(3px);
    z-index: 1;
    box-shadow: 0 5px 20px 1px var(--color-five-opaque-dark);
    transition: all 300ms ease-in-out;
    -webkit-animation: unfold 1s ease-in-out;
            animation: unfold 1s ease-in-out;
  }

  @-webkit-keyframes unfold {
    from {
      right: 3000px;
    }
    to {
      right: 15px;
    }
  }

  @keyframes unfold {
    from {
      right: 3000px;
    }
    to {
      right: 15px;
    }
  }

  .single-product-back-button:hover {
    background-color: var(--color-two);
    color: var(--color-one-opaque);
    cursor: pointer;
    box-shadow: none;
  }
}

@media only screen and (min-width: 376px) {
  .SingleProduct {
    display: grid;
    min-height: 100%;
    height: auto;
    grid-template-rows: 350px 70px auto;
    background-color: var(--color-three-opaque);
    -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px);
  }

  .single-product-image {
    display: block;
    margin: 0 auto;
    width: 400px;
    position: relative;
    top: -60px;
    padding-bottom: 30px;
    -webkit-animation: info-fade-in 600ms ease-in-out backwards;
            animation: info-fade-in 600ms ease-in-out backwards;
  }

  .toner-image {
    width: 400px;
    padding: 60px 30px 10px 30px;
  }

  .single-product-name {
    padding: 25px 0;
    -webkit-backdrop-filter: blur(1px);
            backdrop-filter: blur(1px);
    text-align: center;
    -webkit-animation: info-fade-in 600ms ease-in-out;
            animation: info-fade-in 600ms ease-in-out;
    color: var(--color-one);
    box-shadow: inset 0 8px 30px rgba(255, 218, 218, 0.664);
  }

  .single-product-info {
    box-shadow: inset 2px 12px 32px rgb(144, 121, 121);
    padding: 25px;
    background-color: var(--color-one-more-opaque);
    -webkit-animation: info-fade-in 600ms ease-in-out;
            animation: info-fade-in 600ms ease-in-out;
  }

  @-webkit-keyframes info-fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes info-fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .single-product-refrigeration {
    margin: 0 20px 20px 0;
  }

  .single-product-refrigeration-icon {
    margin-right: 3px;
    position: relative;
    top: 2px;
  }

  .single-product-refrigeration-text {
    padding: 3px;
    background-color: var(--color-four-opaque);
    border-radius: 3px;
    margin-left: 3px;
  }

  .single-product-time {
    margin-bottom: 20px;
  }

  .single-product-time-icon {
    position: relative;
    top: 3px;
    margin-right: 3px;
  }

  .single-product-time-text {
    padding: 3px;
    background-color: var(--color-four-opaque);
    border-radius: 3px;
    margin-left: 3px;
  }

  .single-product-goal-list {
    margin-top: 25px;
    line-height: 1.5;
    font-size: 0.9rem;
    max-width: 85vw;
    background-color: var(--color-four-opaque);
    padding: 10px 10px 10px 25px;
    border-radius: 5px;
    margin-left: 25px;
    max-width: 350px;
  }

  .single-product-goal-list > li {
    margin-top: 5px;
  }

  .single-product-ingredients {
    margin: 30px 0;
  }

  .single-product-ingredients > h4 {
    padding: 3px;
    background-color: var(--color-four-opaque);
    border-radius: 3px;
    width: 100%;
    display: inline;
  }

  .single-product-ingredients-list {
    margin-top: 10px;
    font-size: 0.8rem;
    background-color: var(--color-four-opaque);
    padding: 5px;
    border-radius: 5px;
    line-height: 1.4;
  }

  .single-product-back-button {
    position: fixed;
    bottom: 15px;
    right: 15px;
    color: var(--color-two);
    border: none;
    max-height: 40px;
    border-radius: 6px;
    font-size: 1.2rem;
    padding: 8px 8px 3px 8px;
    background-color: var(--color-nine);
    -webkit-backdrop-filter: blur(3px);
            backdrop-filter: blur(3px);
    z-index: 1;
    transition: all 300ms ease-in-out;
    -webkit-animation: unfold 1s ease-in-out;
            animation: unfold 1s ease-in-out;
    box-shadow: inset 0 3px 8px #cbeef3, inset 0 -3px 8px #607174,
      0 15px 20px 1px var(--color-five-opaque-dark);
  }

  @-webkit-keyframes unfold {
    from {
      right: 3000px;
    }
    to {
      right: 15px;
    }
  }

  @keyframes unfold {
    from {
      right: 3000px;
    }
    to {
      right: 15px;
    }
  }

  .single-product-back-button:hover {
    background-color: var(--color-two);
    color: var(--color-nine);
    cursor: pointer;
    box-shadow: inset 2px 3px 8px #2f0a1e, inset -2px -3px 8px #d3589b;
  }
}

.WrongUrl {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-animation: info-fade-in 900ms ease-in-out;
          animation: info-fade-in 900ms ease-in-out;
}

.wrong-url-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 50px;
  gap: 50px;
  background-color: var(--color-two-opaque);
  color: var(--color-one);
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
  border-radius: 10px;
  width: 90vw;
  max-width: 400px;
  height: 300px;
  text-align: center;
}

.redirect {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}

.redirect > a {
  margin-bottom: 10px;
  color: var(--color-one);
  transition: all 300ms ease-in-out;
}
.redirect > a:hover {
  color: var(--color-eight);
  font-weight: bold;
  letter-spacing: 3px;
  font-size: 20px;
}

