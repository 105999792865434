.WeeklyRoutine {
  display: grid;
  grid-template-rows: 100px auto;
  position: relative;
  overflow-x: hidden;
}

.weekly-headline {
  background-image: linear-gradient(
    0deg,
    rgba(204, 168, 169, 0.26),
    rgba(204, 168, 169, 0.856)
  );
  backdrop-filter: blur(8px);
  text-align: center;
  padding: 15px 0;
  color: var(--color-one);
  position: fixed;
  width: 100vw;
  z-index: 5;
  animation: drop-in 500ms ease-in-out;
}

.weekly-main {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100vw;
}

.add-button-weekly {
  text-decoration: none;
  position: fixed;
  bottom: 20px;
  padding: 5px 16px 0px;
  border-radius: 50%;
  font-size: 3rem;
  font-weight: bold;
  border: 5px solid var(--color-two);
  color: var(--color-two);
  background-color: var(--color-thirteen-opaque);
  backdrop-filter: blur(2px);
  cursor: pointer;
  animation: button-up 800ms cubic-bezier(0.8, 0.02, 0.54, 1.83) 800ms backwards;
  transition: transform 300ms ease-in-out;
  z-index: 5;
}

.add-button-weekly:hover {
  transform: rotate(360deg);
}

@keyframes button-up {
  from {
    opacity: 0;
    bottom: 100px;
  }
  to {
    opacity: 1;
    bottom: 20px;
  }
}

.empty-routine-background {
  background-color: var(--color-one);
  height: 100%;
  overflow-y: hidden;
}

.empty-slogan {
  text-align: center;
  transform: translateX(12%);
}

.dropper {
  width: 290px;
  position: relative;
  left: -53px;
  bottom: 35px;
}

.bottle-row {
  width: 2920px;
  position: fixed;
  bottom: 0px;
  left: 0;
}

.empty-routine-button {
  position: relative;
  animation: none;
  bottom: -235px;
  right: 141px;
  border: none;
  background-color: transparent;
  color: var(--color-three);
  font-size: 70px;
  font-weight: lighter;
  backdrop-filter: none;
  z-index: 5;
}

.empty-routine-button:hover {
  transform: none;
  color: var(--color-nine);
}

@media only screen and (min-height: 750px) {
  .dropper {
    width: 290px;
    position: relative;
    left: -53px;
    bottom: -45px;
  }

  .empty-routine-button {
    bottom: -310px;
  }
}

@media only screen and (max-width: 360px) {
  .empty-slogan {
    transform: none;
  }
  .dropper {
    left: -90px;
  }
  .empty-routine-button {
    bottom: 187px;
    right: -4px;
  }
}
