.Checkbox {
  padding: 10px 0;
}

.time-of-day {
  padding-top: 5px;
}

.weekday-checkbox,
.morning-checkbox,
.evening-checkbox {
  cursor: pointer;
}

.weekday-label,
.morning-label,
.evening-label {
  padding-left: 5px;
  cursor: pointer;
}

.morning,
.evening {
  position: relative;
  font-size: 0.9rem;
}

.morning {
  padding-right: 15px;
}

.morning-label,
.evening-label {
  position: relative;
  top: 4px;
  font-size: 1.1rem;
}
