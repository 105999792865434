.NameFilter {
  margin-bottom: 10px;
  border: 2px solid var(--color-three);
  border-radius: 30px;
  display: flex;
  height: 40px;
  width: 40px;
  padding: 3px;
  position: relative;
  transition: all 300ms ease-in-out;
  overflow: hidden;
  box-shadow: 0 0 10px var(--color-three);
}

.NameFilter:hover {
  border: 2px solid var(--color-two);
}

.name-search-bar {
  flex-grow: 1;
  font-size: 1rem;
  background-color: transparent;
  color: var(--color-two);
  border: none;
  padding: 0 10px 5px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  line-height: 35px;
  opacity: 0;
  cursor: pointer;
}

.name-search-bar:focus {
  outline: 0;
}

.NameFilter:focus-within {
  width: 270px;
  border: 2px solid var(--color-two);
}

.NameFilter:focus-within .name-search-bar {
  opacity: 1;
  cursor: text;
  width: 200px;
}

.name-search-button {
  font-size: 1.2em;
  border: none;
  background: transparent;
  border-radius: 50%;
  cursor: pointer;
  color: var(--color-two);
  padding: 6px;
  margin-left: auto;
  transition: 600ms ease-in-out;
}

.NameFilter:focus-within .name-search-button {
  background-color: var(--color-three);
  color: var(--color-one);
}
