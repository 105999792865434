.WeekDayCard {
  width: 95vw;
  max-width: 730px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  color: var(--color-eight);
}

.WeekDayCard:last-child {
  margin-bottom: 70px;
}

.weekday-headline {
  margin: 0 0 10px 10px;
  font-size: 1.4rem;
  text-shadow: 2px 2px 2px rgba(150, 150, 150, 1);
}

.weekday-box {
  height: auto;
  width: 95vw;
  max-width: 530px;
  background-color: var(--color-four-opaque-dark);
  backdrop-filter: blur(5px);
  border-radius: 30px;
  border: 1px solid var(--color-four-opaque);
  margin-bottom: 40px;
  position: relative;
  box-shadow: 0 15px 30px 5px rgba(0, 0, 0, 0.3);
  display: grid;
  grid-template-columns: [left] minmax(166.25px, 360px) [right] minmax(
      166.25px,
      360px
    );
  grid-template-rows: auto, auto auto;
  animation: entrance 800ms ease-in-out;
}

@keyframes entrance {
  from {
    transform: scale(0);
    opacity: 0;
    z-index: -1;
  }
  to {
    transform: scale(1);
    opacity: 1;
    z-index: 2;
  }
}

.weekday-box::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 50%;
  background-color: rgba(255, 255, 255, 0.287);
  border-radius: 30px 0 0 30px;
}

.weekday-box-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-column-start: 1;
  grid-column-end: 3;
  padding: 5px 25px 0;
  color: var(--color-ten);
  font-size: 1.5rem;
  margin: 10px 0 15px;
}

.sun {
  font-size: 2.1rem;
}

.product-name-list {
  list-style: none;
  padding-left: 15px;
  font-size: 0.75rem;
}

.morning {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;
  max-width: 285px;
}

.evening {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 3;
  max-width: 285px;
  justify-self: end;
}

.see-details-button-wrapper {
  grid-column-start: 1;
  grid-column-end: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  z-index: 1;
}

.see-details-button {
  padding: 15px 20px;
  border-radius: 15px;
  border: none;
  background: linear-gradient(145deg, #c28585, #8b5f5f);
  color: var(--color-one);
  font-weight: bold;
  font-size: 0.9rem;
  box-shadow: 0px 5px 18px #835959e7;
  cursor: pointer;
  transition: all 300ms ease-in-out;
}

.see-details-button:hover {
  background: linear-gradient(145deg, #8b5f5f, #c28585);
  box-shadow: none;
}
