.WarnModal {
  height: 180px;
  width: 300px;
  background-color: var(--color-two);
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);

  z-index: 100;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  border-radius: 15px;
  font-size: 0.9rem;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
  color: var(--color-one);
  padding: 0 10px;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.486);
}

.warn-button-okay {
  cursor: pointer;
  color: var(--color-one);
  padding: 10px 15px;
  letter-spacing: 1px;
  border: none;
  border-radius: 7px;
  background: linear-gradient(145deg, #701847, #5f143b);
  box-shadow: 3px 3px 7px #3a0c25, -3px -3px 7px #941d5c;
}

.warn-button-okay:hover {
  background: linear-gradient(145deg, #5f143b, #701847);
}

.warn-button-okay:active {
  background: #691642;
  box-shadow: inset 3px 3px 7px #3f0d28, inset -3px -3px 7px #881c55;
}
