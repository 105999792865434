.ProductCard {
  width: 270px;
  height: 200px;
  background: linear-gradient(
    145deg,
    var(--color-two-opaque) 40%,
    var(--color-two)
  );
  backdrop-filter: blur(3px);
  border-radius: 35px;
  margin: 0 15px 50px 15px;
  position: relative;
  border: 1px solid var(--color-two-opaque);
  animation: fade-in 1s ease-in-out backwards;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
  transition: 300ms ease-in-out;
}

.ProductCard:hover {
  transform: translateY(-20px);
}

@keyframes fade-in {
  from {
    height: 160px;
    opacity: 0;
  }
  to {
    height: 200px;
    opacity: 1;
  }
}

.ProductCard-headline {
  position: relative;
  right: 7%;
  width: 20ch;
  color: var(--color-seven);
  font-size: 0.8rem;
}

.bottle-image {
  position: absolute;
  left: -165px;
  bottom: -15px;
  width: 380px;
  transform: rotate(-15deg);
  z-index: 0;
  pointer-events: none;
  animation: rotate 1s ease-in-out backwards;
}

@keyframes rotate {
  from {
    transform: rotate(-30deg);
  }
  to {
    transform: rotate(-15deg);
  }
}

.smaller-image {
  position: absolute;
  left: -110px;
  bottom: -5px;
  width: 290px;
}

.powder {
  position: absolute;
  left: -85px;
  bottom: -5px;
  width: 250px;
}

.details-link {
  position: relative;
  right: 25%;
  text-decoration: none;
  color: var(--color-one);
  border: 2px solid var(--color-one);
  padding: 5px 8px;
  border-radius: 5px;
  background-color: var(--color-four-opaque);
  backdrop-filter: blur(1px);
  background: linear-gradient(145deg, #946c7dd0, #af8095d0);
  box-shadow: 4px 4px 18px #33252b;
  transition: 100ms ease-in-out;
}

.details-link:hover {
  transform: translateY(3px);
  box-shadow: none;
}

.add-to-routine-button {
  position: relative;
  right: 5%;
  color: var(--color-two-opaque);
  background: linear-gradient(145deg, #ffffff, #c5bfc2);
  box-shadow: 3px 3px 9px var(--color-two-opaque),
    -3px -3px 19px var(--color-one-opaque);
  border: none;
  border-radius: 10px;
  font-size: 1.7rem;
  line-height: 2rem;
  width: 45px;
  height: 45px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 300ms ease-in-out;
}

.add-to-routine-button:hover {
  transform: rotate(180deg);
}

.long-name {
  position: relative;
  right: 5%;
  bottom: 2%;
  color: var(--color-two-opaque);
  background: linear-gradient(145deg, #ffffff, #c5bfc2);
  box-shadow: 3px 3px 9px var(--color-two-opaque),
    -3px -3px 9px var(--color-one-opaque);
  border: none;
  border-radius: 10px;
  font-size: 1.7rem;
  line-height: 2rem;
  width: 45px;
  height: 45px;
  cursor: pointer;
}
