.DailyRoutine {
  display: grid;
  grid-template-rows: 100px auto;
  position: relative;
}

.daily-headline {
  background-image: linear-gradient(
    0deg,
    rgba(204, 168, 169, 0.26),
    rgba(204, 168, 169, 0.856)
  );
  backdrop-filter: blur(8px);
  color: var(--color-one);
  text-align: center;
  padding: 15px 0;
  position: fixed;
  width: 100vw;
  z-index: 1;
  animation: drop-in 500ms ease-in-out;
}

.history-back {
  color: var(--color-two);
  border: 2px solid var(--color-two);
  border-radius: 6px;
  margin-right: 25px;
  font-size: 1.2rem;
  padding: 8px 8px 3px 8px;
  background-color: var(--color-one-opaque);
  backdrop-filter: blur(3px);
  position: fixed;
  right: -15px;
  top: 10px;
  z-index: 1;
  box-shadow: 0 5px 20px 1px var(--color-five-opaque-dark);
  transition: all 300ms ease-in-out;
}

.history-back:hover {
  background-color: var(--color-two);
  color: var(--color-one-opaque);
  cursor: pointer;
  box-shadow: none;
}

.daily-main {
  padding-top: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.daily-not-modal {
  min-height: 100vh;
  position: relative;
}

.daily-not-modal::after {
  content: "";
  position: absolute;
  background: rgba(190, 190, 190, 0.336);
  inset: 0;
  z-index: 9;
  backdrop-filter: blur(5px);
}

.morning-products-display,
.evening-products-display {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
}

.morning-products-list,
.evening-products-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
}

.added-product-list-item {
  list-style: none;
}
